import { isServer } from "@app/utils"

const BREAKPOINT_XXL = "1440px"
const BREAKPOINT_XL = "1200px"
const BREAKPOINT_SM = "576px"

export type CalculateItemHeightArguments = {
  width: number,
  height: number,
  masonrySizerWidth: number
}
function calculateItemHeight({ width, height, masonrySizerWidth }: CalculateItemHeightArguments) {
  if (isServer) {
    return null
  }

  let columnWidth = null

  columnWidth = masonrySizerWidth / 5

  if (window.matchMedia(`(max-width: ${BREAKPOINT_XXL})`).matches) {
    columnWidth = masonrySizerWidth / 4
  }

  if (window.matchMedia(`(max-width: ${BREAKPOINT_XL})`).matches) {
    columnWidth = masonrySizerWidth / 3
  }

  if (window.matchMedia(`(max-width: ${BREAKPOINT_SM})`).matches) {
    columnWidth = masonrySizerWidth / 2
  }

  // Вертикальное фото
  if (height > width) {
    const aspectRatio = height / width
    return columnWidth * aspectRatio
  }

  // Горизонтальное фото
  if (width > height) {
    const aspectRatio = width / height
    return columnWidth / aspectRatio
  }

  // Квадратное фото
  if (width === height) {
    return columnWidth
  }
}

export { calculateItemHeight }
