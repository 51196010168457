import { useGlobalState } from "@app/contexts"
import { useSharingModal } from "@app/features/sharing/store/hooks"
import { isNativeSharingAvailable, showNativeSharingWindow } from "@app/features/sharing/utils"

function useSharing() {
  const { $showSharingModal } = useSharingModal()
  const {
    userAgent: { isMobile, isTablet },
  } = useGlobalState()

  async function share(
    type: "gallery" | "photo" | "video",
    { title, url, fileURL }: { title: string; url: string; fileURL?: string }
  ) {
    if (isNativeSharingAvailable(isMobile, isTablet)) {
      await showNativeSharingWindow({
        title,
        url,
      })
    } else {
      $showSharingModal({
        type,
        cover: fileURL,
        link: url,
      })
    }
  }

  return share
}

export { useSharing }
