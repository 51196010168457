import React from "react"
import { useI18n } from "next-localization"
import cx from "clsx"

import styles from "./hiding-media-file.module.scss"

import { COLORS, QA_CLASS_NAMES } from "@app/constants"
import { TYPE_REQUEST_HIDING_MEDIA_FILES } from "@app/features/hiding-media-files/constants"
import { useHidingMediaFile } from "@app/features/hiding-media-files/hooks"
import { updateHidingMediaFiles } from "@app/features/hiding-media-files/requests"
import { useHidingMediaFilesStore } from "@app/features/hiding-media-files/store"
import { Hidden, HiddenMobile } from "@app/ui/icons"
import { Tooltip } from "@app/ui/tooltip"
import { isMobileDevice } from "@app/utils"

function HidingMediaFile({
  type = "photo",
  mediaFileById,
  buttonClassName,
  hidingClassName,
  tooltipPosition,
  isMobile,
  classNameIcon,
}) {
  const i18n = useI18n()
  const isMobileView = isMobileDevice()

  const { addToHidingMediaFilesList, removeFromHidingMediaFilesList, hidingMediaFilesList } = useHidingMediaFilesStore()
  const { $setHidingMediaFile, $setShowHiddenMediaFile } = useHidingMediaFile()
  const [isHiddenMediaFile, setIsHiddenMediaFile] = React.useState(mediaFileById?.isHidden)
  const tooltipHidden =
    type === "video"
      ? isHiddenMediaFile
        ? i18n.t("tooltip.showVideo")
        : i18n.t("tooltip.hideVideo")
      : isHiddenMediaFile
      ? i18n.t("tooltip.showPhoto")
      : i18n.t("tooltip.hidePhoto")
  const color = isHiddenMediaFile ? COLORS.black : COLORS.white

  React.useEffect(() => {
    // const idHiddenMediaFiles = hidingMediaFilesList.map((item) => item.id)
    // setIsHiddenMediaFile(idHiddenMediaFiles.includes(mediaFileById?.id))

    const isHiddenMediaFile = !!hidingMediaFilesList.find((item) => item.id === mediaFileById?.id)
    setIsHiddenMediaFile(isHiddenMediaFile)
  }, [hidingMediaFilesList, mediaFileById])

  function handleHiddenClick(e) {
    e.stopPropagation()

    const value = isHiddenMediaFile ? TYPE_REQUEST_HIDING_MEDIA_FILES["show"] : TYPE_REQUEST_HIDING_MEDIA_FILES["hide"]
    const arrayIdMediaFiles = [mediaFileById.id]

    if (isHiddenMediaFile) {
      removeFromHidingMediaFilesList(mediaFileById)
      $setShowHiddenMediaFile(mediaFileById.id)
    } else {
      addToHidingMediaFilesList(mediaFileById)
      $setHidingMediaFile(mediaFileById.id)
    }
    updateHidingMediaFiles({ value, arrayIdMediaFiles })
  }

  const isHideBtn = isMobile && !isHiddenMediaFile

  return (
    <div className={cx({ [styles["hide"]]: isHideBtn })}>
      <Tooltip text={tooltipHidden} position={tooltipPosition}>
        <button className={buttonClassName} onClick={handleHiddenClick}>
          <div
            className={cx(styles["button"], hidingClassName, !isHiddenMediaFile && classNameIcon, {
              [styles["hiding"]]: isHiddenMediaFile,
            })}
          >
            {!isMobileView && <Hidden width={21} heigth={16} fill={color} stroke={color} />}
            {isMobileView && <HiddenMobile width={15} heigth={15} fill={color} stroke={color} />}
          </div>
        </button>
      </Tooltip>
    </div>
  )
}

export { HidingMediaFile }
