function getCurrentGalleryDefaultFavoritesList(favoriteLists, currentGalleryId) {
  const isFavoriteListsExist = !!favoriteLists?.length
  if (!isFavoriteListsExist) {
    return false
  }

  if (isFavoriteListsExist) {
    return favoriteLists.find((favoritesList) => favoritesList.gallery.id === currentGalleryId)
  }
}

export { getCurrentGalleryDefaultFavoritesList }
