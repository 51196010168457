import React from "react"

import { useOnboarding } from "@app/features/onboarding/store/hooks"
import { isFirstVisit } from "@app/features/onboarding/utils"
import {
  IS_FIRST_VISIT_MOBILE_LOCAL_STORAGE_KEY,
  IS_FIRST_VISIT_LOCAL_STORAGE_KEY,
  START_TIME_PRINT_ONBOARDING,
} from "@app/features/onboarding/constants"

function initPrintOnboarding() {
  const isMobileOnboardingDownloadAllNotShown = isFirstVisit(IS_FIRST_VISIT_MOBILE_LOCAL_STORAGE_KEY)
  const isMobileOnboardingNotShown = isFirstVisit(IS_FIRST_VISIT_LOCAL_STORAGE_KEY)
  const {
    $showOnboardingPrint,
    $isOnboardingTourVisible,
    $isTimeInOnboarding,
    $startTimeInOnboarding,
    $isTimeOutOnboarding,
    $stopTimeOutOnboarding,
  } = useOnboarding()

  const printOnboarding = function (isStop = false) {
    if (isStop) {
      if (!isMobileOnboardingDownloadAllNotShown && !isMobileOnboardingNotShown) {
        $showOnboardingPrint()
      } else {
        if (!$isOnboardingTourVisible) {
          $showOnboardingPrint()
        }
      }
    }
  }

  React.useEffect(() => {
    // Если активна Печать фотопродукции
    // запускаем один раз таймер - !$isTimeInOnboarding
    if (!$isTimeInOnboarding) {
      $startTimeInOnboarding() // обозначаем, что время запустилось
      console.log("Start Time Print Onboarding")

      setTimeout(() => {
        console.log("STOP Time Print Onboarding")
        $stopTimeOutOnboarding() // обозначаем, что время вышло
      }, START_TIME_PRINT_ONBOARDING)
    }
  }, [])

  React.useEffect(() => {
    printOnboarding($isTimeOutOnboarding)
  }, [$isOnboardingTourVisible, $isTimeOutOnboarding])
}

export { initPrintOnboarding }
