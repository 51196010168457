const isNativeSharingAvailable = (isMobile: boolean, isTablet: boolean): boolean => {
  if ((isMobile || isTablet) && navigator.share) {
    return true
  }

  return false
}

async function showNativeSharingWindow({ title, url }: { title: string; url: string }) {
  try {
    await navigator.share({
      title,
      url,
    })
  } catch (error) {
    console.error(error)
  }
}

export { isNativeSharingAvailable, showNativeSharingWindow }
