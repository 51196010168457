import { usePhotoCarouselStore } from "features/photo-carousel/store"

const selector = (state) => state.setPhotoLikeStatus

function usePhotoLike() {
  const setPhotoLikeStatus = usePhotoCarouselStore(selector)

  return setPhotoLikeStatus
}

export { usePhotoLike }
