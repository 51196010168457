import { useSharingModalStore, IState } from "features/sharing/store"

const isSharingModalVisibleSelector = (state: IState) => state.isSharingModalVisible
const sharingItemDataSelector = (state: IState) => state.sharingItemData
const showSharingModalSelector = (state: IState) => state.showSharingModal
const hideSharingModalSelector = (state: IState) => state.hideSharingModal

function useSharingModal() {
  const $isSharingModalVisible = useSharingModalStore(isSharingModalVisibleSelector)
  const $sharingItemData = useSharingModalStore(sharingItemDataSelector)
  const $showSharingModal = useSharingModalStore(showSharingModalSelector)
  const $hideSharingModal = useSharingModalStore(hideSharingModalSelector)

  return { $showSharingModal, $hideSharingModal, $isSharingModalVisible, $sharingItemData }
}

export { useSharingModal }
