import cx from "clsx"

import { QuantityMinus, QuantityPlus } from "@app/ui/icons"
import { IQuantityPhotoStepperProps } from "./index.d"

import { QA_CLASS_NAMES } from "@app/constants"

import styles from "./quantity-photo-stepper.module.scss"

const QuantityPhotoStepper = ({
  onIncrementButtonClick,
  onDecrementButtonClick,
  value,
}: IQuantityPhotoStepperProps) => {
  return (
    <div className={styles["root"]}>
      <button
        className={cx(styles["button"], QA_CLASS_NAMES.product.modalSet.less)}
        onClick={() => onDecrementButtonClick()}
      >
        <QuantityMinus className={styles["icon"]} width="16" height="16" />
      </button>
      <span className={styles["value"]}>{value}</span>
      <button
        className={cx(styles["button"], QA_CLASS_NAMES.product.modalSet.more)}
        onClick={() => onIncrementButtonClick()}
      >
        <QuantityPlus className={styles["icon"]} width="16" height="16" />
      </button>
    </div>
  )
}

export { QuantityPhotoStepper }
