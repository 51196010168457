import React from "react"
import cx from "clsx"
import { useInView } from "react-intersection-observer"

import { ActionBarVideo } from "features/masonry/components"
import { VideoStatus } from "@app/ui/icons"

import { useLinkVideoInView } from "@app/utils/hooks"
import { useGetAllPhotos, usePhotoCarousel } from "features/photo-carousel/store/hooks"
import { useHidingMediaFile } from "@app/features/hiding-media-files/hooks"
import { useDisableContextMenu } from "utils"

import styles from "./video-card.module.scss"

/**
 * @deprecated
 */
function VideoCard({ video, isDownloadEnabled, isCanBeHidden, isHiddenScene, isActionsVisible = true }) {
  const { id, fileKey, name, height, width, isHidden, contentType } = video

  const { $isPhotoCarouselVisible, $showPhotoCarousel, $setActivePhoto } = usePhotoCarousel()
  const $allMediaFiles = useGetAllPhotos()

  function handleVideoClick() {
    if (isActionsVisible) {
      const videoIndex = $allMediaFiles.findIndex((mediaFile) => mediaFile.id === id)
      $setActivePhoto(videoIndex)
      $showPhotoCarousel()
      return
    }
  }

  const [isHovered, setIsHovered] = React.useState(false)
  function handleMouseEnter() {
    setIsHovered(true)
  }

  const ref = React.useRef()
  const [inViewRef, inView] = useInView({
    // triggerOnce: true, // если убираем эту строку, тогда изображения обновляются в обе стороны по скролу
    rootMargin: "150px 0px",
  })

  const setRefs = React.useCallback(
    // https://www.npmjs.com/package/react-intersection-observer#how-can-i-assign-multiple-refs-to-a-component
    (node) => {
      ref.current = node
      inViewRef(node)
    },
    [inViewRef]
  )

  useDisableContextMenu(ref, true)

  const videoType =
    contentType === "video/webm" ? 'video/webm; codecs="vp8, vorbis"' : 'video/mp4; codecs="avc1.42E01E, mp4a.40.2"'

  const { srcVideo, isReadyVideo } = useLinkVideoInView(fileKey, contentType, inView)

  function handleMouseLeave() {
    setIsHovered(false)
  }

  function handleMouseMove() {
    if (!isHovered) {
      setIsHovered(true)
    }
  }

  const [isEnergySavingMode, setIsEnergySavingMode] = React.useState(false)
  const startPlayVideo = (video) => {
    video
      .play()
      .then(() => {
        setIsEnergySavingMode(false)
      })
      .catch((error) => {
        setIsEnergySavingMode(true)
        console.log(error)
      })
  }

  const { $hidingMediaFile, $showHiddenMediaFile } = useHidingMediaFile()
  const [isHiddenMediaFile, setIsHiddenMediaFile] = React.useState(isHidden)

  React.useEffect(() => {
    if ($hidingMediaFile === id) {
      setIsHiddenMediaFile(true)
    }
  }, [id, $hidingMediaFile])

  React.useEffect(() => {
    if ($showHiddenMediaFile === id) {
      setIsHiddenMediaFile(false)
    }
  }, [id, $showHiddenMediaFile])

  const refVideo = React.useRef(null)
  const playbackTime = 2.5 // sec
  const srcVideoWithTime = `${srcVideo}#t=,00:00:03`
  const IS_AUTO_PLAY = true
  const typeStartPlay = "auto" // "hover"

  React.useEffect(() => {
    if (IS_AUTO_PLAY && inView && isReadyVideo && refVideo.current && !$isPhotoCarouselVisible) {
      const video = refVideo.current

      video.controls = false
      video.pause()
      video.currentTime = 0

      if (typeStartPlay === "auto") {
        startPlayVideo(video)
      }

      // if (typeStartPlay === "hover" && isHovered) {
      //   video.play()
      // }

      video.addEventListener("timeupdate", function () {
        if (video.currentTime >= playbackTime) {
          video.currentTime = 0
        }
      })
    }
  }, [isReadyVideo, refVideo, inView, $isPhotoCarouselVisible])

  const radiusHoverIcon = isHovered ? 39.25 : 35.25

  return (
    <div
      className={cx(
        styles["root"],
        !isDownloadEnabled && "image-context-menu-disabler",
        isHiddenMediaFile && "image-hidden"
      )}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onMouseMove={handleMouseMove}
      ref={setRefs}
    >
      {inView && (
        <React.Fragment>
          {isReadyVideo && !$isPhotoCarouselVisible && (
            <div className={styles["card"]} onClick={handleVideoClick}>
              {isEnergySavingMode && (
                <div className={styles["icon"]}>
                  <VideoStatus radius={radiusHoverIcon} />
                </div>
              )}
              <video
                ref={refVideo}
                preload="metadata"
                width="100%"
                height="100%"
                controls={false}
                autoPlay={false}
                loop
                muted
                playsInline
                className={styles["video"]}
              >
                <source src={srcVideoWithTime} type={videoType} />
                Video не поддерживается вашим браузером.
              </video>
            </div>
          )}
          {isActionsVisible && (
            <ActionBarVideo
              id={id}
              src={srcVideo}
              fileKey={fileKey}
              type={contentType}
              visible={isHovered}
              isDownloadEnabled={isDownloadEnabled}
              name={name}
              isCanBeHidden={isCanBeHidden}
              isHiddenScene={isHiddenScene}
            ></ActionBarVideo>
          )}
        </React.Fragment>
      )}
    </div>
  )
}

export { VideoCard }
