import { FC, memo, ReactElement, useCallback, useMemo, useRef, useState } from "react"
import { IntersectionOptions, useInView } from "react-intersection-observer"

import { MediaFile } from "../../types"
import { LazyImage } from "../lazy-image"

import styles from "./photo-card.module.scss"

import { SIZE } from "@app/constants"
import { useGlobalState } from "@app/contexts"
import { IClientAlbumPhoto, IGalleryPhoto } from "@app/features/book-editor-v2/@types"
import { useLinkImageInView } from "@app/utils"

const TYPE_SIZE = "w"
const INSTERSECTION_OPTIONS: IntersectionOptions = {
  rootMargin: "150px 0px",
}

type RenderOverlayProps = {
  isHovered: boolean
  photo: MediaFile | IClientAlbumPhoto
  srcImage: string
}

const isMediaFile = (photo: unknown): photo is MediaFile => (
  typeof (photo as MediaFile).photos?.[0] !== 'undefined'
)

type PhotoCardProps = {
  photo: MediaFile | IClientAlbumPhoto,
  onClick?: () => void
  renderOverlay?: ({ isHovered, photo, srcImage }: RenderOverlayProps) => ReactElement
}
const PhotoPreview: FC<PhotoCardProps> = ({ photo, renderOverlay, onClick }) => {
  const shouldRenderOverlay = typeof renderOverlay === "function"
  const { imageProxyHost, imgFormat } = useGlobalState()

  const ref = useRef()
  const [inViewRef, inView] = useInView(INSTERSECTION_OPTIONS)

  /**
   * NOTE: https://www.npmjs.com/package/react-intersection-observer#how-can-i-assign-multiple-refs-to-a-component
   */
  const setRefs = useCallback(
    (node) => {
      ref.current = node
      inViewRef(node)
    },
    [inViewRef]
  )

  const { srcImage, isReadyImage } = useLinkImageInView(
    SIZE.l,
    TYPE_SIZE,
    isMediaFile(photo) ? photo.photos?.[0] : photo.fileKey,
    imgFormat,
    imageProxyHost,
    photo.contentType,
    inView
  )

  const [isHovered, setIsHovered] = useState(false)

  const handleMouseEnter = () => {
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  const handleMouseMove = () => {
    setIsHovered(true)
  }

  return (
    <div
      ref={setRefs}
      className={styles["root"]}
      onMouseEnter={() => handleMouseEnter()}
      onMouseMove={() => handleMouseMove()}
      onMouseLeave={() => handleMouseLeave()}
    >
      {isReadyImage && (
        <LazyImage image={srcImage} onClick={!shouldRenderOverlay ? onClick : undefined} />
      )}
      {shouldRenderOverlay && (
        <div className={styles["overlay-container"]} onClick={() => onClick?.()}>
          {renderOverlay({ isHovered: isHovered, photo, srcImage })}
        </div>
      )}
    </div>
  )
}

export { PhotoPreview }
