import * as React from "react"
import PropTypes from "prop-types"
import JustifiedGrid from "react-justified-grid"
import cx from "clsx"

import { useGlobalState } from "@app/contexts"
import { PhotoCard } from "features/masonry/components/photo-card"
import { VideoCard } from "features/masonry/components/video-card"

import { isMobileDevice } from "@app/utils"

import { QA_CLASS_NAMES } from "@app/constants"

import styles from "./horizontal.module.scss"

/**
 * @deprecated
 */
const MasonryHorizontal = ({ mediaFiles, type, favoritesListId, isDownloadEnabled, isCanBeHidden, isHiddenScene }) => {
  const [mediaFilesList, setMediaFilesList] = React.useState([])
  const maxRowHeight = isMobileDevice() ? 300 : 650
  const { size } = useGlobalState()

  React.useEffect(() => {
    setMediaFilesList(() => {
      let tmpMediaFiles = mediaFiles.map((file) => {
        return {
          height: file.height,
          width: file.width,
          src: file.type === "photo" ? file.photos[0] : file.fileKey,
        }
      })
      return [...tmpMediaFiles]
    })
  }, [mediaFiles])

  return (
    <React.Fragment>
      <div className={styles["root"]}>
        {mediaFilesList.length > 0 && (
          <JustifiedGrid
            images={mediaFilesList}
            rows={undefined}
            maxRowHeight={maxRowHeight}
            showIncompleteRow={true}
            gutter={0}
          >
            {(processedImages) => {
              return (
                <>
                  {processedImages.map((file, key) => {
                    const photo = mediaFiles[key]?.type === "photo" && mediaFiles[key]
                    const video = mediaFiles[key]?.type === "video" && mediaFiles[key]
                    const { width, height, left, top } = file

                    if (photo) {
                      return (
                        <div
                          key={key}
                          className={cx(styles["item"], QA_CLASS_NAMES.photo.photo)}
                          style={{ width, height, left, top }}
                        >
                          <PhotoCard
                            key={photo.id}
                            photo={photo}
                            pictureSize={size.l}
                            masonrySizerWidth={file.width}
                            type={type}
                            favoritesListId={favoritesListId}
                            isDownloadEnabled={isDownloadEnabled}
                            isCanBeHidden={isCanBeHidden}
                            isHiddenScene={isHiddenScene}
                          />
                        </div>
                      )
                    }

                    if (video) {
                      return (
                        <div key={key} className={styles["item"]} style={{ width, height, left, top }}>
                          <VideoCard
                            key={video.id}
                            video={video}
                            masonrySizerWidth={file.width}
                            isDownloadEnabled={isDownloadEnabled}
                            isCanBeHidden={isCanBeHidden}
                            isHiddenScene={isHiddenScene}
                          />
                        </div>
                      )
                    }
                  })}
                </>
              )
            }}
          </JustifiedGrid>
        )}
      </div>
    </React.Fragment>
  )
}

MasonryHorizontal.propTypes = {
  photos: PropTypes.array,
  type: PropTypes.string,
  favoritesListId: PropTypes.string,
  isDownloadEnabled: PropTypes.bool,
}

export { MasonryHorizontal }
