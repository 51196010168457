import cx from "clsx"

import styles from "./progress-circle.module.scss"

function ProgressCircle({ fill = "transparent", stroke, className }) {
  return (
    <svg height="32" width="32" viewBox="0 0 35 35" className={cx(styles["root"], className)}>
      <circle cx="17.5" cy="17.5" r="16" stroke={stroke} strokeWidth="1.5" fill={fill} />
    </svg>
  )
}

export { ProgressCircle }
