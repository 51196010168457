type TTYPE_REQUEST = {
  hide: string
  show: string
}

const TYPE_REQUEST_HIDING_MEDIA_FILES: TTYPE_REQUEST = {
  hide: "hide",
  show: "show",
}

export { TYPE_REQUEST_HIDING_MEDIA_FILES }
