import { FC } from 'react'
import { useI18n } from 'next-localization'

import styles from './pages-labels-horizontal.module.scss'

type PagesLabelsHorizontalProps = {
    pages: Array<{ index: number }>
}
const PagesLabelsHorizontal: FC<PagesLabelsHorizontalProps> = ({ pages }) => {
    const { t } = useI18n()

    return (
        <div className={styles['root']}>
            { pages.map(({ index }) => (
                <div key={index} className={styles['label']}>
                    {index < 0 
                    ? t("bookEditor.cover") 
                    : t("bookEditor.page", { number: index + 1 }) 
                    }
                </div>
            ))}
        </div>
    )
}

export { PagesLabelsHorizontal }