import create, { State } from "zustand"

export interface IState extends State {
  isSharingModalVisible: boolean
  sharingItemData: null | { type: string; cover?: string; link?: string }
  showSharingModal: (sharingItemData: { type: string; cover: string; link?: string }) => void
  hideSharingModal: () => void
}

const useSharingModalStore = create<IState>((set) => ({
  isSharingModalVisible: false,
  sharingItemData: null,
  showSharingModal: (sharingItemData) => set(() => ({ isSharingModalVisible: true, sharingItemData })),
  hideSharingModal: () => set(() => ({ isSharingModalVisible: false, sharingItemData: null })),
}))

export { useSharingModalStore }
