import { usePhotoCarouselStore } from "features/photo-carousel/store"

const selector = (state) => state.allPhotos

function useGetAllPhotos() {
  const allPhotos = usePhotoCarouselStore(selector)

  return allPhotos
}

export { useGetAllPhotos }
