import create from "zustand"

import { IPhotoSetEditorPhoto, IPhotoSetEditorStore } from "@app/features/photo-set-editor/@types"

const usePhotoSetEditorStore = create<IPhotoSetEditorStore>((set) => ({
  photos: [],
  applyPhotoSet: (photos) => set((state) => ({ ...state, ...photos })),
  addPhoto: (photo_id: string) =>
    set((state) => {
      let photos: IPhotoSetEditorPhoto[] = JSON.parse(JSON.stringify(state.photos))
      photos.push({
        id: photo_id,
        quantity: 1,
      })
      return { ...state, photos }
    }),
  deletePhoto: (photo_id: string) =>
    set((state) => {
      let photos: IPhotoSetEditorPhoto[] = JSON.parse(JSON.stringify(state.photos))
      let removablePhotoIndex: number = photos.findIndex((photo) => photo.id === photo_id)
      photos.splice(removablePhotoIndex, 1)

      return { ...state, photos }
    }),
  updatePhotoQuantity: (photo_id: string, factor: number) =>
    set((state) => {
      let photos: IPhotoSetEditorPhoto[] = JSON.parse(JSON.stringify(state.photos))
      let updatablePhotoIndex: number = photos.findIndex((photo, index) => photo.id === photo_id)
      let updatablePhoto: IPhotoSetEditorPhoto = photos[updatablePhotoIndex]

      photos[updatablePhotoIndex] = {
        ...updatablePhoto,
        quantity: updatablePhoto.quantity + factor,
      }

      return { ...state, photos }
    }),
}))

export { usePhotoSetEditorStore }
