import * as React from "react"
import { CSSTransition } from "react-transition-group"

import styles from "./smooth-transition.module.scss"

interface SmoothTransitionPropsInterface {
  visible: boolean
  timeout?: number
  children: React.ReactNode
}

const defaultAnimationConfig = {
  enter: styles["enter-animation"],
  enterActive: styles["enter-animation-active"],
  exit: styles["exit-animation"],
  exitActive: styles["exit-animation-active"],
}

const SmoothTransition = ({ visible, timeout = 200, children }: SmoothTransitionPropsInterface) => {
  return (
    <CSSTransition in={visible} timeout={timeout} classNames={defaultAnimationConfig} unmountOnExit>
      {children}
    </CSSTransition>
  )
}

export { SmoothTransition }
