import { GallerySettingsInterface } from "@app/features/gallery/types"

function checkOnboardingActivation(gallerySettings: GallerySettingsInterface): boolean {
  if (gallerySettings && gallerySettings.hasOwnProperty("isOnboardingEnabled")) {
    return gallerySettings.isOnboardingEnabled
  }

  return true
}

export { checkOnboardingActivation }
