import { api } from "@app/api"

import { FavoritesListInterface } from "@app/features/favorites/types"
import { REQUEST_TYPE } from "@app/api/constants"

async function addPhotoToFavoritesListAR(photoId, favoritesListId) {
  try {
    await api().post(`/lists/${favoritesListId}/photos`, {
      photo: photoId,
    })
  } catch (error) {
    console.error(error.response)

    return null
  }
}

async function removePhotoFromFavoritesListAR(photoId, favoritesListId) {
  try {
    await api().delete(`/lists/${favoritesListId}/photos`, {
      data: {
        photo: photoId,
      },
    })
  } catch (error) {
    console.error(error.response)

    return null
  }
}

interface FetchFavoritesListRequestParams {
  galleryId: string
}

/* TODO:
      Вся работа с избранным и обработка данных завязана на массивы,
      отсутствуют типы и описания состояний, нарушены принципы SOLID, DRY, etc.
      Что бы зарефакторить нужно подойти более системно к задаче [VIGBO-2090],
      это огромный пласт функционала, рефакторинг которого займет более чем 1 спринт...
      по этому пока костыль.

      Так же хотелось бы завести DTO, совсем не ясно какими моделями общается клиент и сервер.
 */
async function fetchFavoriteLists(
  { galleryId }: FetchFavoritesListRequestParams,
  requestType: REQUEST_TYPE = REQUEST_TYPE.CLIENT,
  ctx?: any
) {
  try {
    const { data } = await api(requestType, ctx).get(`/lists/gallery/${galleryId}/my`)

    /*
     Если избранного нет, то апи вернет null, типы не описаны нигде,
     нет гарантии что обработает null, по этому возвращаем пустой массив в случе null.
     */
    if (data?.data == null) {
      return []
    }

    /*
       А данные по избранному возвращаем как массив, ровно по той же причине.
     */
    return [data.data]
  } catch (error) {
    console.error(error.response)

    return null
  }
}

async function createFavoritesListAR({ gallery, description, name }) {
  try {
    const { data } = await api().post("/lists", {
      gallery,
      description,
      name,
    })

    return data.data
  } catch (error) {
    console.error(error.response)

    return null
  }
}

async function fetchFavoritesList({ requestType, ctx, favoritesListId }) {
  try {
    const { data } = await api(requestType, ctx).get(`/lists/${favoritesListId}`)

    return data.data
  } catch (error) {
    console.error(error.response)

    return null
  }
}

async function createDefaultFavoritesListAR(galleryId) {
  try {
    const { data } = await api().post("/lists", {
      gallery: galleryId,
      description: "",
      name: "Избранное",
    })

    return data.data
  } catch (error) {
    console.error(error.response)

    return null
  }
}

async function sendCommentForFavoritesList({ text, favoritesListId }: { text: string; favoritesListId: string }) {
  try {
    const { data } = await api().post(`/lists/${favoritesListId}/comments`, {
      text,
    })

    return data
  } catch (error) {
    console.error(error.response)

    return error.response.data
  }
}

async function updateFavoritesList({
  key,
  value,
  favoritesListId,
}: {
  key: keyof FavoritesListInterface
  value: any
  favoritesListId: string
}) {
  try {
    await api().patch(`/lists/${favoritesListId}`, {
      [key]: value,
    })
  } catch (error) {
    console.error(error.response)
  }
}

export {
  addPhotoToFavoritesListAR,
  removePhotoFromFavoritesListAR,
  fetchFavoriteLists,
  createFavoritesListAR,
  fetchFavoritesList,
  createDefaultFavoritesListAR,
  sendCommentForFavoritesList,
  updateFavoritesList,
}
