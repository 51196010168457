import React, { FunctionComponent, ReactElement } from "react"

import styles from "./photos-select.module.scss"

import { QuantityPhotoStepper } from "@app/features/photo-set-editor/components/quantity-photo-stepper"
import { MAX_PHOTOS_SELECT } from "@app/features/photo-set-editor/constants"
import { usePhotosSelectViewModel } from "@app/features/photo-set-editor/hooks"

type TPhotosSelectProps = {
  photo: any,
}

const PhotosSelect: FunctionComponent<TPhotosSelectProps> = ({ photo }: TPhotosSelectProps): ReactElement => {
  const { photo: orderedPhoto, totalPhotos, increment, decrement } = usePhotosSelectViewModel(photo.id)

  return (
    <div className={styles["root"]}>
      {orderedPhoto === null && totalPhotos < MAX_PHOTOS_SELECT && <div className={styles["selection"]}></div>}
      {orderedPhoto !== null && (
        <div className={styles["quantity"]}>
          <QuantityPhotoStepper
            onIncrementButtonClick={() => increment()}
            onDecrementButtonClick={() => decrement()}
            value={orderedPhoto.quantity}
          />
        </div>
      )}
    </div>
  )
}

export { PhotosSelect }
