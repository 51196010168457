import { useFavoritePhotosStore } from "features/masonry/store"

const selector = (state) => state.setFavoritePhotos

function useSetFavoritePhotos() {
  const setFavoritePhotos = useFavoritePhotosStore(selector)

  return setFavoritePhotos
}

export { useSetFavoritePhotos }
