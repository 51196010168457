import React, { FunctionComponent } from "react"
import { useI18n } from "next-localization"
import cn from "clsx"

import { Modal } from "@app/ui/modal"
import { Tooltip } from "@app/ui/tooltip"
import { isClient, isMobileDevice } from "@app/utils"

import styles from "./pages-labels.module.scss"

type TPagesLabelsProps = {
  pages: Array<any>
  cover?: boolean
  overlay?: boolean
}

const getDisplayLabelsCount = (cover: boolean, pagesLength: number): number => {
  if (cover && pagesLength < 3) return pagesLength
  if (cover && pagesLength >= 3) return 1
  if (!cover && pagesLength < 4) return pagesLength
  if (!cover && pagesLength >= 4) return 2

  return pagesLength
}

const PagesLabels: FunctionComponent<TPagesLabelsProps> = ({
  pages,
  cover = false,
  overlay = false,
}: TPagesLabelsProps) => {
  const { t } = useI18n()
  const [isVisibleMobilePages, setIsVisibleMobilePages] = React.useState(false)

  const displayLabelsCount = getDisplayLabelsCount(cover, pages.length)

  const visiblePages = [...pages].splice(0, displayLabelsCount)
  const hiddenPages = [...pages].splice(displayLabelsCount).map(({ index }) => `Страница ${index + 1}`)

  const isMobile = isClient ? isMobileDevice() : false

  return (
    <>
      <div className={cn(styles["root"], { [styles["overlay"]]: overlay })}>
        {cover && <span className={styles["label"]}>Обложка</span>}
        {visiblePages.map(({ index }) => (
          <span className={styles["label"]} key={index}>
            {index < 0 
              ? t("bookEditor.cover") 
              : t("bookEditor.page", { number: index + 1 }) 
            }
          </span>
        ))}
        {isMobile && hiddenPages.length > 0 && (
          <>
            <span onClick={() => setIsVisibleMobilePages(true)} className={styles["label"]}>
              …
            </span>
            <Modal
              modalClassName={styles["mobile-hidden-pages-modal"]}
              open={isVisibleMobilePages}
              onClose={() => setIsVisibleMobilePages(false)}
              isMobileType={true}
            >
              <span className={styles["mobile-hidden-pages"]}>{hiddenPages.join(", ")}</span>
            </Modal>
          </>
        )}
        {!isMobile && hiddenPages.length > 0 && (
          <Tooltip text={hiddenPages.join(", ")}>
            <span className={styles["label"]}>…</span>
          </Tooltip>
        )}
      </div>
    </>
  )
}

export { PagesLabels }
