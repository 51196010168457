import React from "react"

function useMasonrySizerWidth() {
  const masonrySizerRef = React.useRef()
  const [masonrySizerWidth, setMasonrySizerWidth] = React.useState()

  React.useEffect(() => {
    function measureMasonrySizerWidth() {
      if (masonrySizerRef?.current) {
        setMasonrySizerWidth(masonrySizerRef.current.getBoundingClientRect().width)
      }
    }

    measureMasonrySizerWidth()

    window.addEventListener("resize", measureMasonrySizerWidth, false)
    window.addEventListener("orientationchange", measureMasonrySizerWidth, false)

    return () => {
      window.removeEventListener("resize", measureMasonrySizerWidth, false)
      window.removeEventListener("orientationchange", measureMasonrySizerWidth, false)
    }
  }, [setMasonrySizerWidth])

  return { masonrySizerRef, masonrySizerWidth }
}

export { useMasonrySizerWidth }
