const IS_FIRST_VISIT_LOCAL_STORAGE_KEY = "IS_FIRST_VISIT_LOCAL_STORAGE_KEY"
const IS_FIRST_VISIT_MOBILE_LOCAL_STORAGE_KEY = "IS_FIRST_VISIT_MOBILE_LOCAL_STORAGE_KEY"
const IS_FIRST_VISIT_PRINT_LOCAL_STORAGE_KEY = "IS_FIRST_VISIT_PRINT_LOCAL_STORAGE_KEY"
const STEPS_CLASS_NAME_TOUR = {
  downloadAll: { name: "tour-step__download-all", selector: ".tour-step__download-all" },
  download: { name: "tour-step__download", selector: ".tour-step__download" },
  share: { name: "tour-step__share", selector: ".tour-step__share" },
  like: { name: "tour-step__like", selector: ".tour-step__like" },
}
const START_TIME_PRINT_ONBOARDING = 60000 // = 1 минута / 180000 // = 3 минуты
const PRINT_ONBOARDING_PRINT_HINT = "onboarding-gallery__print-hint"
const PRINT_ONDOARDING_IN_VIEW_MARGIN__DESKTOP = 400
const PRINT_ONDOARDING_IN_VIEW_MARGIN__MOBILE = 440

export {
  IS_FIRST_VISIT_LOCAL_STORAGE_KEY,
  IS_FIRST_VISIT_MOBILE_LOCAL_STORAGE_KEY,
  IS_FIRST_VISIT_PRINT_LOCAL_STORAGE_KEY,
  STEPS_CLASS_NAME_TOUR,
  START_TIME_PRINT_ONBOARDING,
  PRINT_ONBOARDING_PRINT_HINT,
  PRINT_ONDOARDING_IN_VIEW_MARGIN__DESKTOP,
  PRINT_ONDOARDING_IN_VIEW_MARGIN__MOBILE,
}
