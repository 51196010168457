import { useHidingMediaFilesStore } from "@app/features/hiding-media-files/store"

const setHidingMediaFileSelector = (state) => state.setHidingMediaFile
const hidingMediaFileSelector = (state) => state.hidingMediaFile
const setShowHiddenMediaFileSelector = (state) => state.setShowHiddenMediaFile
const showHiddenMediaFileSelector = (state) => state.showHiddenMediaFile

function useHidingMediaFile() {
  const $setHidingMediaFile = useHidingMediaFilesStore(setHidingMediaFileSelector)
  const $hidingMediaFile = useHidingMediaFilesStore(hidingMediaFileSelector)
  const $setShowHiddenMediaFile = useHidingMediaFilesStore(setShowHiddenMediaFileSelector)
  const $showHiddenMediaFile = useHidingMediaFilesStore(showHiddenMediaFileSelector)

  return { $setHidingMediaFile, $hidingMediaFile, $setShowHiddenMediaFile, $showHiddenMediaFile }
}

export { useHidingMediaFile }
