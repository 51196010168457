import React from "react"
const isTouchEvent = (event: Event | TouchEvent): boolean => {
  return "touches" in event
}
const preventDefault = (event: TouchEvent) => {
  if (!isTouchEvent(event)) return
  if (event.touches.length < 2 && event.preventDefault) {
    event.preventDefault()
  }
}
interface ILongPressOptions {
  shouldPreventDefault?: boolean
  delay?: number
}
const useLongPress = (
  onLongPress: (event: Event | TouchEvent) => void,
  onClick: () => void,
  { shouldPreventDefault = true, delay = 300 }: ILongPressOptions = {}
) => {
  const [longPressTriggered, setLongPressTriggered] = React.useState(false)
  const timeout: any = React.useRef()
  const target: any = React.useRef()
  const start = React.useCallback(
    (event) => {
      if (shouldPreventDefault && event.target) {
        event.target.addEventListener("touchend", preventDefault, {
          passive: false,
        })
        target.current = event.target
      }
      timeout.current = setTimeout(() => {
        onLongPress(event)
        setLongPressTriggered(true)
      }, delay)
    },
    [onLongPress, delay, shouldPreventDefault]
  )
  const clear = React.useCallback(
    (event, shouldTriggerClick = true) => {
      timeout.current && clearTimeout(timeout.current)
      shouldTriggerClick && !longPressTriggered && onClick()
      setLongPressTriggered(false)
      if (shouldPreventDefault && target.current) {
        target.current.removeEventListener("touchend", preventDefault)
      }
    },
    [shouldPreventDefault, onClick, longPressTriggered]
  )
  return {
    onMouseDown: (e) => start(e),
    onTouchStart: (e) => start(e),
    onMouseUp: (e) => clear(e),
    onTouchMove: (e) => clear(e),
    onMouseLeave: (e) => clear(e, false),
    onTouchEnd: (e) => clear(e),
  }
}
export { useLongPress }
