import React from "react"
import { CSSTransition } from "react-transition-group"
import { useRouter } from "next/router"
import { useI18n } from "next-localization"
import cx from "clsx"

import { fetchFavoriteLists } from "features/favorites/requests"
import { checkFavoriteLimit } from "features/favorites/utils"

import styles from "./action-bar.module.scss"

import { COLORS, QA_CLASS_NAMES } from "@app/constants"
import { useGallery } from "@app/contexts"
import { useGlobalState } from "@app/contexts"
import { trackYandexMetrikaAction } from "@app/features/analytics/utils"
import { useDownloadPhoto } from "@app/features/download/hooks"
import { useFavoritesList } from "@app/features/favorites/hooks"
import { HidingMediaFile } from "@app/features/hiding-media-files/components"
import { useGetFavoritePhotos, useSetFavoritePhotos } from "@app/features/masonry/hooks"
import { getPhotoDirectURL } from "@app/features/photo/utils"
import { useGetAllPhotos, usePhotoLike } from "@app/features/photo-carousel/store/hooks"
import { useShowProductsModal } from "@app/features/products/hooks/products-modal"
import { PRODUCTS_MODAL_TYPES } from "@app/features/products/types"
import { useSharing } from "@app/features/sharing/hooks"
import { useAuthorization } from "@app/features/user/stores"
import { Download, Like, Printer, Share } from "@app/ui/icons"
import { ProgressCircle } from "@app/ui/progress-circle"
import { Tooltip } from "@app/ui/tooltip"

function ActionBar({
  visible,
  sizes,
  srcImage,
  photoId,
  type,
  favoritesListId,
  isFavoritePhotoRemoveProgressCS = false,
  setIsFavoritePhotoRemoveProgressCS = () => {},
  setIsPhotoCardLikedCS,
  timerId = null,
  name,
  isDownloadEnabled = true,
  isCanBeHidden = true,
  isHiddenScene = false,
}) {
  const router = useRouter()
  const isFavoritePage = router.pathname.includes("favorites")

  const { isUserAuthorized } = useAuthorization()
  const $allPhotos = useGetAllPhotos()
  const $setPhotoLikeStatus = usePhotoLike()
  const downloadPhoto = useDownloadPhoto(photoId, sizes)
  const like = useFavoritesList({ photoId, imageSrc: srcImage, favoritesListId })

  const i18n = useI18n()

  const { gallery } = useGallery()
  const {
    userAgent: { isMobile },
  } = useGlobalState()

  const isPrintableProductsEnabled = gallery?.isPrintableProductsEnabled

  const share = useSharing()
  function handleShareClick(e) {
    e.stopPropagation()

    trackYandexMetrikaAction("click-hover-share-photo-desktop")
    share("photo", {
      title: name,
      url: getPhotoDirectURL(photoId),
      fileURL: srcImage,
    })
  }

  const photoById = $allPhotos.find((photo) => photo.id === photoId)

  const [isLikedCS, setIsLikedCS] = React.useState(photoById?.isLiked)
  React.useEffect(() => {
    setIsLikedCS(photoById?.isLiked)
    // setIsPhotoCardLikedCS(photoById?.isLiked)
    if (isFavoritePage) {
      setIsLikedCS(true)
      //setIsPhotoCardLikedCS(true)
    }
  }, [$allPhotos])

  const $favoritePhotos = useGetFavoritePhotos()
  const $setFavoritePhotos = useSetFavoritePhotos()

  async function handleLikeClick(e) {
    e.stopPropagation()

    if (!isFavoritePage) {
      like(isLikedCS)

      if (isUserAuthorized) {
        $setPhotoLikeStatus(photoId, !isLikedCS)
        setIsLikedCS(!isLikedCS)

        const favoriteLists = await fetchFavoriteLists({ galleryId: gallery.id })
        const isFull = checkFavoriteLimit(gallery?.id, favoriteLists)
        // Если лимит достигнут, и лайк по фото, то отменяем лайк
        // (именно отмена, чтобы не было задержки при клике на лайк, тк проверка async await)
        if (isFull && !isLikedCS && photoById?.isLiked) {
          setIsLikedCS(false)
          $setPhotoLikeStatus(photoId, false)
        }
      }

      return
    }

    if (isFavoritePage) {
      if (!isFavoritePhotoRemoveProgressCS) {
        setIsFavoritePhotoRemoveProgressCS(true)
        timerId.current = setTimeout(() => {
          const filteredPhotos = $favoritePhotos.filter((photo) => photo.id !== photoId)
          $setFavoritePhotos(filteredPhotos)
          setIsFavoritePhotoRemoveProgressCS(false)
          clearTimeout(timerId.current)
          like(isLikedCS)
        }, 2000)
      } else {
        clearTimeout(timerId.current)
        setIsFavoritePhotoRemoveProgressCS(false)
      }

      return
    }
  }

  const $showProductsModal = useShowProductsModal()

  const { isFavoritesSectionActivated } = useGlobalState()

  function handleDownloadClick(e) {
    e.stopPropagation()

    downloadPhoto()
    trackYandexMetrikaAction("click-hover-download-photo-desktop")
  }

  const isVisibleHiddenBtn = isHiddenScene && isCanBeHidden

  const [isHoverActions, setIsHoverActions] = React.useState(true)
  function handleMouseEnter() {
    setIsHoverActions(true)
  }

  function handleMouseLeave() {
    setIsHoverActions(false)
  }

  return (
    <React.Fragment>
      {isVisibleHiddenBtn && (
        <CSSTransition
          in={visible}
          timeout={{
            enter: 0,
            exit: 300,
          }}
          classNames={{
            enter: styles["enter"],
            enterActive: styles["enter-active"],
            enterDone: styles["enter-done"],
            exit: styles["exit"],
            exitActive: styles["exit-active"],
          }}
          unmountOnExit
        >
          <div className={styles["action-wrap"]}>
            <div className={styles["action-top"]}>
              {isCanBeHidden && (
                <div
                  className={cx(styles["action"], styles["visible"])}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <HidingMediaFile
                    mediaFileById={photoById}
                    buttonClassName={cx(styles["button"], QA_CLASS_NAMES.photo.hiding)}
                    isMobile={isHiddenScene}
                  ></HidingMediaFile>
                </div>
              )}
            </div>
          </div>
        </CSSTransition>
      )}

      {!isMobile && (
        <CSSTransition
          in={visible}
          timeout={{
            enter: 0,
            exit: 300,
          }}
          classNames={{
            enter: styles["enter"],
            enterActive: styles["enter-active"],
            enterDone: styles["enter-done"],
            exit: styles["exit"],
            exitActive: styles["exit-active"],
          }}
          unmountOnExit
        >
          <div className={styles["action-wrap"]}>
            <div className={styles["action-top"]}>
              {isCanBeHidden && !isVisibleHiddenBtn && (
                <div
                  className={cx(styles["action"], { [styles["action-blur"]]: isHoverActions })}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <HidingMediaFile
                    mediaFileById={photoById}
                    buttonClassName={cx(styles["button"], QA_CLASS_NAMES.photo.hiding)}
                  ></HidingMediaFile>
                </div>
              )}
            </div>
            <div className={styles["action-bar"]}>
              <div
                className={cx(styles["actions"], { [styles["actions-blur"]]: isHoverActions })}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                {isPrintableProductsEnabled && (
                  <div className={styles["action"]}>
                    <Tooltip text={i18n.t("tooltip.printPhoto")}>
                      <button
                        className={cx(styles["button"], QA_CLASS_NAMES.photo.print)}
                        onClick={() => $showProductsModal(PRODUCTS_MODAL_TYPES.DEFAULT)}
                      >
                        <Printer stroke={COLORS.white} />
                      </button>
                    </Tooltip>
                  </div>
                )}
                {isDownloadEnabled && (
                  <div className={styles["action"]}>
                    <Tooltip text={i18n.t("tooltip.downloadPhoto")}>
                      <button
                        className={cx(styles["button"], QA_CLASS_NAMES.photo.download)}
                        onClick={handleDownloadClick}
                      >
                        <Download width="18" height="18" stroke={COLORS.white}></Download>
                      </button>
                    </Tooltip>
                  </div>
                )}
                <div className={styles["action"]}>
                  <Tooltip text={i18n.t("common.share")}>
                    <button
                      className={cx(styles["button"], styles["share-button"], QA_CLASS_NAMES.photo.share)}
                      onClick={handleShareClick}
                    >
                      <Share width="21" height="17" fill={COLORS.white}></Share>
                    </button>
                  </Tooltip>
                </div>
                {isFavoritesSectionActivated && (
                  <Tooltip text={i18n.t("tooltip.addToFavorites")}>
                    <div className={styles["action"]}>
                      <button
                        className={cx(
                          styles["button"],
                          {
                            [styles["disabled"]]: isLikedCS,
                          },
                          QA_CLASS_NAMES.photo.favourites
                        )}
                        onClick={handleLikeClick}
                      >
                        <Like
                          width="18"
                          height="16"
                          className={cx({
                            [styles["liked"]]: isLikedCS,
                          })}
                          fill="none"
                        ></Like>
                      </button>
                    </div>
                  </Tooltip>
                )}
              </div>
            </div>
          </div>
        </CSSTransition>
      )}
      {isFavoritesSectionActivated && isLikedCS && (
        <div className={styles["liked-status-wrapper"]}>
          <Tooltip text={i18n.t("tooltip.removeFromFavorites")}>
            <button
              className={cx(
                styles["liked-photo"],
                {
                  [styles["without-background"]]: visible,
                },
                QA_CLASS_NAMES.photo.outFavourites
              )}
              onClick={handleLikeClick}
            >
              <Like
                className={cx(styles["like"], {
                  [styles["like-small"]]: isFavoritePhotoRemoveProgressCS,
                })}
                stroke="#fff"
                fill={isFavoritePhotoRemoveProgressCS ? "transparent" : "#fff"}
              ></Like>
              {isFavoritePhotoRemoveProgressCS && (
                <ProgressCircle stroke="#fff" className={styles["remove-progress-circle"]}></ProgressCircle>
              )}
            </button>
          </Tooltip>
        </div>
      )}
    </React.Fragment>
  )
}

export { ActionBar }
