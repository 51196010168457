import React from "react"
import cx from "clsx"

import { useI18n } from "next-localization"

import { useHidingMediaFilesStore } from "@app/features/hiding-media-files/store"
import { useHidingMediaFile } from "@app/features/hiding-media-files/hooks"
import { updateHidingScene } from "@app/features/hiding-media-files/requests"
import { TYPE_REQUEST_HIDING_MEDIA_FILES } from "@app/features/hiding-media-files/constants"

import { isMobileDevice } from "@app/utils"

import { Tooltip } from "@app/ui/tooltip"
import { Hidden, HiddenMobile } from "@app/ui/icons"

import { IconWrapper } from "@app/features/theme/icon-wrapper"
import { COLORS, QA_CLASS_NAMES } from "@app/constants"

import styles from "./hiding-scene.module.scss"

function HidingScene({ id, mediaFiles, isMediaFilesExist, isHiddenScene, isAllMediaFilesHidden }) {
  const i18n = useI18n()
  const isMobile = isMobileDevice()

  const { addToHidingMediaFilesList, removeFromHidingMediaFilesList, hidingMediaFilesList } = useHidingMediaFilesStore()
  const { $setHidingMediaFile, $setShowHiddenMediaFile } = useHidingMediaFile()
  const [isAllMediaFilesHiddenST, setIsAllMediaFilesHiddenST] = React.useState(isAllMediaFilesHidden)
  const tooltipHidden = isAllMediaFilesHiddenST ? i18n.t("tooltip.showScene") : i18n.t("tooltip.hideScene")
  const color = isAllMediaFilesHiddenST ? COLORS.black : COLORS.white

  React.useEffect(() => {
    if (isMediaFilesExist) {
      const idHiddenMediaFiles = hidingMediaFilesList.map((item) => item.id)
      const idMediaFilesScene = mediaFiles.map((file) => file.id)
      const isSceneHidden = isHiddenScene || idMediaFilesScene.every((item) => idHiddenMediaFiles.includes(item))
      setIsAllMediaFilesHiddenST(isSceneHidden)
    }
  }, [hidingMediaFilesList])

  function handleHiddenClick() {
    const value = isAllMediaFilesHiddenST
      ? TYPE_REQUEST_HIDING_MEDIA_FILES["show"]
      : TYPE_REQUEST_HIDING_MEDIA_FILES["hide"]

    if (isAllMediaFilesHiddenST) {
      mediaFiles.forEach((file) => {
        removeFromHidingMediaFilesList(file)
        $setShowHiddenMediaFile(file.id)
      })
    } else {
      mediaFiles.forEach((file) => {
        addToHidingMediaFilesList(file)
        $setHidingMediaFile(file.id)
      })
    }
    updateHidingScene({ id, value })
  }

  return (
    <Tooltip text={tooltipHidden}>
      <div
        className={cx(
          { [QA_CLASS_NAMES.scene.show]: isAllMediaFilesHiddenST },
          { [QA_CLASS_NAMES.scene.hide]: !isAllMediaFilesHiddenST }
        )}
        onClick={handleHiddenClick}
      >
        <div
          className={cx(styles["button"], {
            [styles["hiding"]]: isAllMediaFilesHiddenST,
          })}
        >
          {!isMobile && (
            <IconWrapper
              light={<Hidden width={21} height={16} />}
              dark={<Hidden width={21} height={16} fill={color} stroke={color} />}
            />
          )}
          {isMobile && (
            <IconWrapper
              light={<HiddenMobile width={15} height={15} />}
              dark={<HiddenMobile width={15} height={15} fill={color} stroke={color} />}
            />
          )}
        </div>
      </div>
    </Tooltip>
  )
}

export { HidingScene }
