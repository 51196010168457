import { usePhotoCarouselStore } from "features/photo-carousel/store"

const selector = (state) => state.setAllPhotos

function useSetAllPhotos() {
  const setAllPhotos = usePhotoCarouselStore(selector)

  return setAllPhotos
}

export { useSetAllPhotos }
