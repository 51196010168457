import { getSavedProduct } from "@app/features/book-editor/utils"
import { usePhotoSetEditorStore } from "@app/features/photo-set-editor/store"
import { useClientSide } from "@app/features/book-editor-v2/hooks/use-client-side"

const useApplyPhotoSetFromLocalStorage = (product: string, galleryId: string) => {
  const $applyPhotoSet = usePhotoSetEditorStore((store) => store.applyPhotoSet)

  useClientSide(() => {
    const savedPhotoSet = getSavedProduct(product, galleryId)
    if (savedPhotoSet !== null) {
      const savedPhotos = savedPhotoSet.productConfiguration
      $applyPhotoSet(savedPhotos)
    }
  })
}

export { useApplyPhotoSetFromLocalStorage }
