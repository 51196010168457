import { useRouter } from "next/router"

import { ADDED_TO_FAVORITES_NOTIFICATION_TYPE } from "features/notification/constants"
import { useNotification } from "features/notification/store/hooks"
import { useGallery } from "@app/contexts"
import { useAuthorizationModal } from "@app/features/user/stores"
import { useLimitModal } from "@app/features/user/stores"
import { checkAccessToken } from "features/user/utils"
import { checkFavoriteLimit } from "features/favorites/utils"
import {
  addPhotoToFavoritesListAR,
  createDefaultFavoritesListAR,
  fetchFavoriteLists,
  removePhotoFromFavoritesListAR,
} from "features/favorites/requests"
import { useLikedPhoto, useSetFavoriteLists } from "features/favorites/store/hooks"
import { getFavoriteLists } from "features/favorites/store/utils"
import { getCurrentGalleryDefaultFavoritesList } from "features/favorites/utils"

function useFavoritesList({ isRedirectToFavoritesListPage = false, photoId, imageSrc, favoritesListId } = {}) {
  const router = useRouter()
  const $setFavoriteLists = useSetFavoriteLists()
  const { showAuthorizationModal } = useAuthorizationModal()
  const { showLimitModal } = useLimitModal()
  const { $showNotification } = useNotification()
  const { $setLikedPhoto } = useLikedPhoto()
  const { gallery } = useGallery()
  const galleryId = gallery.id

  function redirectToDefaultFavoritesListPage(id) {
    router.push(`/favorites-list/${id}`)
  }

  function showSuccessNotification(image, id) {
    $showNotification({
      type: ADDED_TO_FAVORITES_NOTIFICATION_TYPE,
      image,
      link: `/favorites-list/${id}`,
    })
  }

  async function like(isLiked) {
    // Нет токена
    if (!checkAccessToken()) {
      $setLikedPhoto({
        photoId,
        imageSrc,
      })
      showAuthorizationModal()

      return
    }

    // Есть токен и включен редирект на страницу избранного ( клик по лайку в шапке )
    if (checkAccessToken() && isRedirectToFavoritesListPage) {
      // Есть избранный список принадлежащий к текущей галерее
      if (getCurrentGalleryDefaultFavoritesList(getFavoriteLists(), galleryId)) {
        redirectToDefaultFavoritesListPage(getCurrentGalleryDefaultFavoritesList(getFavoriteLists(), galleryId).id)

        return
      }

      // Нет избранных списков принадлежащий к текущей галерее
      if (!getCurrentGalleryDefaultFavoritesList(getFavoriteLists(), galleryId)) {
        await createDefaultFavoritesListAR(galleryId)
        const favoriteLists = await fetchFavoriteLists({ galleryId })
        $setFavoriteLists(favoriteLists)
        const defaultFavoritesListId = getCurrentGalleryDefaultFavoritesList(favoriteLists, galleryId).id
        redirectToDefaultFavoritesListPage(defaultFavoritesListId)

        return
      }
    }

    // Есть токен и выключен редирект на страницу избранного ( клик по лайку на карточке и в превью )
    if (checkAccessToken() && !isRedirectToFavoritesListPage) {
      if (isLiked) {
        const defaultFavoritesListId = getCurrentGalleryDefaultFavoritesList(getFavoriteLists(), galleryId).id
        await removePhotoFromFavoritesListAR(photoId, favoritesListId ? favoritesListId : defaultFavoritesListId)

        const favoriteLists = await fetchFavoriteLists({ galleryId })
        $setFavoriteLists(favoriteLists)

        return
      }

      // Есть избранный список принадлежащей данной галерее
      if (getCurrentGalleryDefaultFavoritesList(getFavoriteLists(), galleryId)) {
        const favoriteLists = await fetchFavoriteLists({ galleryId })
        const isFull = checkFavoriteLimit(galleryId, favoriteLists)
        if (!isFull) {
          await addPhotoToFavoritesListAR(
            photoId,
            getCurrentGalleryDefaultFavoritesList(getFavoriteLists(), galleryId).id
          )
          showSuccessNotification(imageSrc, getCurrentGalleryDefaultFavoritesList(getFavoriteLists(), galleryId).id)

          const favoriteLists = await fetchFavoriteLists({ galleryId })
          $setFavoriteLists(favoriteLists)
        } else {
          showLimitModal()
        }

        return
      }

      // Нет избранных списоков принадлежащих данной галерее
      if (!getCurrentGalleryDefaultFavoritesList(getFavoriteLists(), galleryId)) {
        await createDefaultFavoritesListAR(galleryId)

        const favoriteLists = await fetchFavoriteLists({ galleryId })
        $setFavoriteLists(favoriteLists)

        const defaultFavoritesListId = getCurrentGalleryDefaultFavoritesList(favoriteLists, galleryId).id
        await addPhotoToFavoritesListAR(photoId, defaultFavoritesListId)

        showSuccessNotification(imageSrc, getCurrentGalleryDefaultFavoritesList(getFavoriteLists(), galleryId).id)

        const newFavoriteLists = await fetchFavoriteLists({ galleryId })
        $setFavoriteLists(newFavoriteLists)

        return
      }
    }
  }

  return like
}

export { useFavoritesList }
