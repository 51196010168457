import { useHidingMediaFilesStore } from "@app/features/hiding-media-files/store"

const selector = (state) => state.setHidingMediaFilesList

function useSetHidingMediaFilesList() {
  const setHidingMediaFilesList = useHidingMediaFilesStore(selector)

  return setHidingMediaFilesList
}

export { useSetHidingMediaFilesList }
