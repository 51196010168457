import { usePhotoCarouselStore } from "features/photo-carousel/store"

const visibilitySelector = (state) => state.isPhotoCarouselVisible
const showPhotoCarouselSelector = (state) => state.showPhotoCarousel
const hidePhotoCarouselSelector = (state) => state.hidePhotoCarousel
const activePhotoSelector = (state) => state.activePhoto
const setActivePhotoSelector = (state) => state.setActivePhoto

function usePhotoCarousel() {
  const $isPhotoCarouselVisible = usePhotoCarouselStore(visibilitySelector)
  const $showPhotoCarousel = usePhotoCarouselStore(showPhotoCarouselSelector)
  const $hidePhotoCarousel = usePhotoCarouselStore(hidePhotoCarouselSelector)
  const $activePhoto = usePhotoCarouselStore(activePhotoSelector)
  const $setActivePhoto = usePhotoCarouselStore(setActivePhotoSelector)

  return { $isPhotoCarouselVisible, $showPhotoCarousel, $hidePhotoCarousel, $activePhoto, $setActivePhoto }
}

export { usePhotoCarousel }
