import React from "react"
import cx from "clsx"

import { useImageLoading } from "@app/utils"
import { GoogleImage } from "@app/features/google-image-service-status/components/google-image"
import { useSaveImageNotify } from "@app/features/masonry/hooks/use-save-image-notify"

import styles from "./lazy-image.module.scss"

interface LazyImagePropsInterface {
  image: string
  onClick: () => void
  className?: string
}

const LazyImage = ({ image, onClick, className }: LazyImagePropsInterface) => {
  const { isImageLoaded, onImageLoad } = useImageLoading()
  const { events: longTapEvents } = useSaveImageNotify()

  return (
    <figure onClick={onClick} className={cx(styles["root"], className)}>
      <GoogleImage
        {...longTapEvents}
        src={image}
        className={cx(styles["image"], {
          [styles["loaded"]]: isImageLoaded,
          [styles["cover"]]: true,
        })}
        onLoad={onImageLoad}
        alt=""
      />
    </figure>
  )
}

export { LazyImage }
