import create, { State } from "zustand"

export interface IState extends State {
  isOnboardingVisible: boolean
  showOnboarding: () => void
  hideOnboarding: () => void

  isOnboardingPrintVisible: boolean
  showOnboardingPrint: () => void
  hideOnboardingPrint: () => void

  isOnboardingTourVisible: boolean
  showOnboardingTour: () => void
  hideOnboardingTour: () => void

  isTimeInOnboarding: boolean
  startTimeInOnboarding: () => void

  isTimeOutOnboarding: boolean
  stopTimeOutOnboarding: () => void
}

const useOnboardingStore = create<IState>((set) => ({
  isOnboardingVisible: false,
  showOnboarding: () => set(() => ({ isOnboardingVisible: true })),
  hideOnboarding: () => set(() => ({ isOnboardingVisible: false })),

  isOnboardingPrintVisible: false,
  showOnboardingPrint: () => set(() => ({ isOnboardingPrintVisible: true })),
  hideOnboardingPrint: () => set(() => ({ isOnboardingPrintVisible: false })),

  isOnboardingTourVisible: false,
  showOnboardingTour: () => set(() => ({ isOnboardingTourVisible: true })),
  hideOnboardingTour: () => set(() => ({ isOnboardingTourVisible: false })),

  isTimeInOnboarding: false,
  startTimeInOnboarding: () => set(() => ({ isTimeInOnboarding: true })),

  isTimeOutOnboarding: false,
  stopTimeOutOnboarding: () => set(() => ({ isTimeOutOnboarding: true })),
}))

export { useOnboardingStore }
