import { useVisibleContextMenu } from "@app/features/context-menu-photo/store"
import { isFirstVisit } from "@app/features/onboarding/utils"
import { IS_FIRST_CONTEXT_MENU_MOBILE_STORAGE_KEY } from "@app/features/context-menu-photo/constants"
import { useLongPress } from "utils/hooks/use-long-press"
import { isClient, isMobileDevice } from "@app/utils"

const useSaveImageNotify = () => {
  const { showContextMenu }: any = useVisibleContextMenu()
  const isFirstVisitGallery = isFirstVisit(IS_FIRST_CONTEXT_MENU_MOBILE_STORAGE_KEY)
  const isMobile = isClient ? isMobileDevice() : false

  const handleLongTap = (e) => {
    if (isMobile && isFirstVisitGallery) showContextMenu()
  }

  const longPressEvent = useLongPress(handleLongTap, () => {}, {
    shouldPreventDefault: false,
    delay: 1500,
  })

  return { events: longPressEvent }
}

export { useSaveImageNotify }
