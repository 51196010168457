import React from "react"

import { MAX_PHOTOS_SELECT } from "@app/features/photo-set-editor/constants"

import { usePhotoSetEditorStore } from "@app/features/photo-set-editor/store"
import { getTotalPhotosQuantity } from "@app/features/photo-set-editor/utils"

import { IPhotoSetEditorPhoto } from "@app/features/photo-set-editor/@types"

interface IPhotosSelectViewModel {
  photo: IPhotoSetEditorPhoto
  totalPhotos: number
  increment: () => void
  decrement: () => void
}

const usePhotosSelectViewModel = (photo_id: string): IPhotosSelectViewModel => {
  const $deletePhoto = usePhotoSetEditorStore((state) => state.deletePhoto)
  const $selectedPhotos = usePhotoSetEditorStore((state) => state.photos)
  const $updatePhotoQuantity = usePhotoSetEditorStore((state) => state.updatePhotoQuantity)

  const photo = $selectedPhotos?.filter((photo) => photo.id == photo_id)[0] || null

  React.useEffect(() => {
    if (photo !== null && photo.quantity <= 0) {
      $deletePhoto(photo.id)
    }
  }, [photo])

  const handleIncrementPhoto = () => {
    if (getTotalPhotosQuantity($selectedPhotos) >= MAX_PHOTOS_SELECT) {
      return
    }

    $updatePhotoQuantity(photo_id, 1)
  }

  return {
    photo,
    totalPhotos: getTotalPhotosQuantity($selectedPhotos),
    increment: () => handleIncrementPhoto(),
    decrement: () => $updatePhotoQuantity(photo_id, -1),
  }
}

export { usePhotosSelectViewModel }
