import { Fragment, useCallback, useEffect, useRef, useState } from "react"
import { useInView } from "react-intersection-observer"
import { useI18n } from "next-localization"
import cx from "clsx"

import { useFavoritesList } from "features/favorites/hooks"
import { ActionBar, LazyImage } from "features/masonry/components"
import { useGetFavoritePhotos, useSetFavoritePhotos } from "features/masonry/hooks"
import { useGetAllPhotos, usePhotoCarousel } from "features/photo-carousel/store/hooks"
import { useDisableContextMenu } from "utils"

import styles from "./photo-card.module.scss"

import { useGlobalState } from "@app/contexts"
import { PagesLabels } from "@app/features/book-editor/components/pages-labels"
import { useHidingMediaFile } from "@app/features/hiding-media-files/hooks"
import { PhotosSelect } from "@app/features/photo-set-editor/components/photos-select"
import { ConfirmationModal } from "@app/ui/confirmation-modal"
import { TrashInCircle } from "@app/ui/icons"
import { Tooltip } from "@app/ui/tooltip"
import { useLinkImageInView } from "@app/utils/hooks"

/**
 * @deprecated
 */
function PhotoCard({
  photo,
  pictureSize,
  masonrySizerWidth,
  type,
  favoritesListId,
  isDownloadEnabled,
  isCanBeHidden,
  isHiddenScene,
  isActionsVisible = true,
  onClick = (photo) => {},
  cover = null,
  pages = [],
  isSelectPhotoActive = false,
  isRemovePhotoActive = false,
  onRemovePhoto = () => {},
}) {
  const i18n = useI18n()
  const { id, photos: sizes, name, height, width, isHidden } = photo

  const { $showPhotoCarousel, $setActivePhoto } = usePhotoCarousel()
  const $allPhotos = useGetAllPhotos()

  const { imageProxyHost, imgFormat } = useGlobalState()

  function handlePhotoClick() {
    if (isActionsVisible) {
      const photoIndex = $allPhotos.findIndex((photo) => photo.id === id)
      $setActivePhoto(photoIndex)
      $showPhotoCarousel()
      return
    }

    onClick(photo)
  }

  const [isHovered, setIsHovered] = useState(false)
  function handleMouseEnter() {
    setIsHovered(true)
  }

  const photo_path = photo?.photos ? photo.photos[0] : photo.fileKey

  const ref = useRef()
  const [inViewRef, inView] = useInView({
    // triggerOnce: true, // если убираем эту строку, тогда изображения обновляются в обе стороны по скролу
    rootMargin: "150px 0px",
  })

  const setRefs = useCallback(
    // https://www.npmjs.com/package/react-intersection-observer#how-can-i-assign-multiple-refs-to-a-component
    (node) => {
      ref.current = node
      inViewRef(node)
    },
    [inViewRef]
  )

  useDisableContextMenu(ref, !isDownloadEnabled)

  const typeSize = "w" // max width
  const { srcImage, isReadyImage } = useLinkImageInView(
    pictureSize,
    typeSize,
    photo_path,
    imgFormat,
    imageProxyHost,
    photo.contentType,
    inView
  )

  const like = useFavoritesList({ photoId: id, imageSrc: srcImage, favoritesListId })

  /*
    TODO: Лютый костыль требует рефакторинга этого комопнента в целом,
    нужно разделить ответсвенности, этот компонент используется и в фотокниге и в холсте и в наборе фото,
    а структура даных везде разная =)
  */
  let usedPages = []

  const isCover = cover?.id === photo?.id || cover?.photo_id === photo?.id
  const isPhotobook = typeof pages[0]?.style !== "undefined"
  const isCanvas = typeof pages[0]?.photo_id !== "undefined"

  if (isPhotobook) {
    usedPages = pages
      .map((page, index) => {
        return { ...page, index }
      })
      .filter((page) => page.photos.findIndex((pagePhoto) => pagePhoto.id === photo.id) > -1)
  }

  if (isCanvas && pages[0].photo_id === photo.id) {
    usedPages = [
      {
        index: 0,
        ...pages[0],
      },
    ]
  }

  const [isFavoritePhotoRemoveProgressCS, setIsFavoritePhotoRemoveProgressCS] = useState(false)
  const [isPhotoCardLikedCS, setIsPhotoCardLikedCS] = useState(false)

  let timerId = useRef()

  const $favoritePhotos = useGetFavoritePhotos()
  const $setFavoritePhotos = useSetFavoritePhotos()

  function handleMouseLeave() {
    setIsHovered(false)
    if (isPhotoCardLikedCS && isFavoritePhotoRemoveProgressCS) {
      like(isPhotoCardLikedCS)
      clearTimeout(timerId.current)
      const filteredPhotos = $favoritePhotos.filter((photo) => photo.id !== id)
      $setFavoritePhotos(filteredPhotos)
    }
  }

  function handleMouseMove() {
    // При скрытии изображения, соседнее становиться на его место,
    // и пока курсор не уйдет с изображения, и снова не вернется
    // не появляются action-bars, поэтому мини хак для того,
    // чтобы отобразить (при малейшем сдвиге курсора)
    if (!isHovered) {
      setIsHovered(true)
    }
  }

  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false)
  function handleRemove() {
    setIsConfirmationVisible(false)
    onRemovePhoto(photo?.id)
  }

  const { $hidingMediaFile, $showHiddenMediaFile } = useHidingMediaFile()
  const [isHiddenMediaFile, setIsHiddenMediaFile] = useState(isHidden)

  useEffect(() => {
    if ($hidingMediaFile === id) {
      setIsHiddenMediaFile(true)
    }
  }, [$hidingMediaFile])

  useEffect(() => {
    if ($showHiddenMediaFile === id) {
      setIsHiddenMediaFile(false)
    }
  }, [$showHiddenMediaFile])

  const isUsedPages = usedPages?.length > 0 || isCover

  return (
    <>
      <div
        className={cx(
          styles["root"],
          !isDownloadEnabled && "image-context-menu-disabler",
          isHiddenMediaFile && "image-hidden"
        )}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onMouseMove={handleMouseMove}
        ref={setRefs}
      >
        {inView && (
          <Fragment>
            {isRemovePhotoActive && (
              <span
                className={cx(styles["remove"], isHovered && styles["active"], isUsedPages && styles["disabled"])}
                onClick={() => {
                  if (!isUsedPages) setIsConfirmationVisible(true)
                }}
              >
                <Tooltip
                  text={isUsedPages ? i18n.t("productModalPhotos.photoUsed") : i18n.t("productModalPhotos.remove")}
                  position="down"
                >
                  <TrashInCircle />
                </Tooltip>
              </span>
            )}
            {isReadyImage && <LazyImage image={srcImage} onClick={handlePhotoClick}></LazyImage>}
            {isUsedPages && <PagesLabels overlay={!isHovered} pages={usedPages} cover={isCover} />}
            {isSelectPhotoActive && <PhotosSelect photo={photo} />}
            {isActionsVisible && (
              <ActionBar
                visible={isHovered}
                sizes={sizes}
                srcImage={srcImage}
                photoId={id}
                type={type}
                favoritesListId={favoritesListId}
                isFavoritePhotoRemoveProgressCS={isFavoritePhotoRemoveProgressCS}
                setIsFavoritePhotoRemoveProgressCS={setIsFavoritePhotoRemoveProgressCS}
                setIsPhotoCardLikedCS={setIsPhotoCardLikedCS}
                timerId={timerId}
                isDownloadEnabled={isDownloadEnabled}
                name={name}
                isCanBeHidden={isCanBeHidden}
                isHiddenScene={isHiddenScene}
              ></ActionBar>
            )}
          </Fragment>
        )}
      </div>

      <ConfirmationModal
        className={styles["modal"]}
        open={isConfirmationVisible}
        acceptText={i18n.t("bookEditor.deleteConfirmationModal.acceptText")}
        declineText={i18n.t("bookEditor.deleteConfirmationModal.declineText")}
        onDecline={() => setIsConfirmationVisible(false)}
        onAccept={handleRemove}
      >
        {i18n.t("bookEditor.deleteConfirmationModal.textDescription")}
      </ConfirmationModal>
    </>
  )
}

export { PhotoCard }
