import create from "zustand"

const useHidingMediaFilesStore = create((set) => ({
  hidingMediaFilesList: [],
  setHidingMediaFilesList: (hidingMediaFilesList) => set(() => ({ hidingMediaFilesList })),
  addToHidingMediaFilesList: (hidingMediaFile) => {
    set((state) => ({
      hidingMediaFilesList: [...state.hidingMediaFilesList, hidingMediaFile],
    }))
  },
  removeFromHidingMediaFilesList: (hidingMediaFile) => {
    set((state) => ({
      hidingMediaFilesList: state.hidingMediaFilesList.filter((file) => file.id !== hidingMediaFile.id),
    }))
  },

  hidingMediaFile: {},
  showHiddenMediaFile: {},
  setHidingMediaFile: (hidingMediaFile) => set(() => ({ hidingMediaFile })),
  setShowHiddenMediaFile: (showHiddenMediaFile) => set(() => ({ showHiddenMediaFile })),
}))

export { useHidingMediaFilesStore }
