import create from "zustand"

interface VisibleContextMenuStore {
  isVisibleContextMenu: boolean
  showContextMenu: () => void
  hideContextMenu: () => void
}

const useVisibleContextMenuStoreStore = create<VisibleContextMenuStore>((set) => ({
  isVisibleContextMenu: false,
  showContextMenu: () => set(() => ({ isVisibleContextMenu: true })),
  hideContextMenu: () => set(() => ({ isVisibleContextMenu: false })),
}))

const isVisibleContextMenuSelector = (state: VisibleContextMenuStore) => state.isVisibleContextMenu
const showContextMenuSelector = (state: VisibleContextMenuStore) => state.showContextMenu
const hideContextMenuSelector = (state: VisibleContextMenuStore) => state.hideContextMenu

function useVisibleContextMenu() {
  const isVisibleContextMenu = useVisibleContextMenuStoreStore(isVisibleContextMenuSelector)
  const showContextMenu = useVisibleContextMenuStoreStore(showContextMenuSelector)
  const hideContextMenu = useVisibleContextMenuStoreStore(hideContextMenuSelector)

  return { isVisibleContextMenu, showContextMenu, hideContextMenu }
}

export { useVisibleContextMenu }
