import { useOnboardingStore, IState } from "features/onboarding/store"

const isOnboardingVisibleSelector = (state: IState) => state.isOnboardingVisible
const showOnboardingSelector = (state: IState) => state.showOnboarding
const hideOnboardingSelector = (state: IState) => state.hideOnboarding

const isOnboardingPrintSelector = (state: IState) => state.isOnboardingPrintVisible
const showOnboardingPrintSelector = (state: IState) => state.showOnboardingPrint
const hideOnboardingPrintSelector = (state: IState) => state.hideOnboardingPrint

const isOnboardingTourSelector = (state: IState) => state.isOnboardingTourVisible
const showOnboardingTourSelector = (state: IState) => state.showOnboardingTour
const hideOnboardingTourSelector = (state: IState) => state.hideOnboardingTour

const isTimeInOnboardingSelector = (state: IState) => state.isTimeInOnboarding
const startTimeInOnboardingSelector = (state: IState) => state.startTimeInOnboarding

const isTimeOutOnboardingSelector = (state: IState) => state.isTimeOutOnboarding
const stopTimeOutOnboardingSelector = (state: IState) => state.stopTimeOutOnboarding

function useOnboarding() {
  const $isOnboardingVisible = useOnboardingStore(isOnboardingVisibleSelector)
  const $showOnboarding = useOnboardingStore(showOnboardingSelector)
  const $hideOnboarding = useOnboardingStore(hideOnboardingSelector)

  const $isOnboardingPrintVisible = useOnboardingStore(isOnboardingPrintSelector)
  const $showOnboardingPrint = useOnboardingStore(showOnboardingPrintSelector)
  const $hideOnboardingPrint = useOnboardingStore(hideOnboardingPrintSelector)

  const $isOnboardingTourVisible = useOnboardingStore(isOnboardingTourSelector)
  const $showOnboardingTour = useOnboardingStore(showOnboardingTourSelector)
  const $hideOnboardingTour = useOnboardingStore(hideOnboardingTourSelector)

  const $isTimeInOnboarding = useOnboardingStore(isTimeInOnboardingSelector)
  const $startTimeInOnboarding = useOnboardingStore(startTimeInOnboardingSelector)

  const $isTimeOutOnboarding = useOnboardingStore(isTimeOutOnboardingSelector)
  const $stopTimeOutOnboarding = useOnboardingStore(stopTimeOutOnboardingSelector)

  return {
    $isOnboardingVisible,
    $showOnboarding,
    $hideOnboarding,
    $isOnboardingPrintVisible,
    $showOnboardingPrint,
    $hideOnboardingPrint,
    $isOnboardingTourVisible,
    $showOnboardingTour,
    $hideOnboardingTour,
    $isTimeInOnboarding,
    $startTimeInOnboarding,
    $isTimeOutOnboarding,
    $stopTimeOutOnboarding,
  }
}

export { useOnboarding }
