import React from "react"

const useVisibleMediaFilesCount = ({ scenes }) => {
  const [count, setCount] = React.useState(0)

  React.useEffect(() => {
    let files = scenes
      .map((scene) => scene.mediaFiles)
      .flat()
      .filter((file) => file.isHidden !== true)
    setCount(files.length)
  }, [scenes])

  return count
}

export { useVisibleMediaFilesCount }
