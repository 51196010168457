import { api } from "@app/api"

async function updateHidingMediaFiles({
  value,
  arrayIdMediaFiles,
}: {
  value: string
  arrayIdMediaFiles: Array<number>
}) {
  try {
    await api().patch(`/media-files/${value}`, {
      ids: arrayIdMediaFiles,
    })
  } catch (error) {
    console.error(error.response)
  }
}

async function updateHidingScene({ id, value }: { id: number; value: string }) {
  try {
    await api().patch(`/scenes/${id}/${value}`)
  } catch (error) {
    console.error(error.response)
  }
}

export { updateHidingMediaFiles, updateHidingScene }
