import React from "react"

import { Container } from "@app/ui/container"

import styles from "./hiding-media-files-head.module.scss"

function HidingMediaFilesHead({ anchor, title }) {
  return (
    <section id={anchor} className={styles["scene"]}>
      <Container className={styles["container"]}>
        <h1 className={styles["title"]}>{title}</h1>
      </Container>
    </section>
  )
}

export { HidingMediaFilesHead }
