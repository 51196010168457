import * as React from "react"
import ReactMasonryComponent from "react-masonry-component"
import cx from "clsx"

import { IClientAlbumPhoto, IGalleryPhoto } from "@app/features/book-editor-v2/@types"
import { useMasonrySizerWidth } from "features/masonry/hooks"

import { calculateItemHeight } from './../../utils'

import styles from "./masonry.module.scss"

const MASONRY_OPTIONS = {
  gutter: 0,
  fitWidth: false,
  horizontalOrder: false,
  percentPosition: true,
  originLeft: true,
  originTop: true,
}

export type MasonryItem = {
  id: string | number,
  width: number,
  height: number,
}

export type MasonryProps<T> = {
  items: T[],
  getItemId: (item: T) => string,
  renderItem: (item: T) => React.ReactElement,
  getItemClassName?: (item: T) => string,
  onReady?: () => void,
}

const Masonry = <T extends MasonryItem | IGalleryPhoto | IClientAlbumPhoto, >({ items, renderItem, getItemId, getItemClassName, onReady = () => {} }: MasonryProps<T>) => {
  const { masonrySizerRef, masonrySizerWidth } = useMasonrySizerWidth()
 
  return (
    <div className={styles["root"]}>
      <div ref={masonrySizerRef}></div>
      {masonrySizerWidth && (
        // @ts-expect-error NOTE: Внутри либы тип не тот для children
        <ReactMasonryComponent options={MASONRY_OPTIONS} onLayoutComplete={() => onReady?.()}>
          {items.map((item) => !!item && (
            <div
              key={item.id}
              id={getItemId(item)}
              className={cx(styles["masonry-item"], getItemClassName?.(item))}
              style={{
                  height: calculateItemHeight({
                    height: item.height,
                    width: item.width,
                    masonrySizerWidth,
                  }),
              }}
            >
              { renderItem(item) }
            </div>
          ))}
          <div className={styles["gutter-sizer"]}></div>
        </ReactMasonryComponent>
      )}
    </div>
  )
}

export { Masonry }
