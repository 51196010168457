const isFirstVisit = (key): boolean => {
  try {
    const value = window?.localStorage?.getItem(key)

    if (value) {
      return JSON.parse(value)
    }
  } catch (error) {
    return true
  }

  return true
}

export { isFirstVisit }
