import create from "zustand"

const usePhotoCarouselStore = create((set) => ({
  isPhotoCarouselVisible: false,
  allPhotos: [],
  activePhoto: null,
  setAllPhotos: (data) => set(() => ({ allPhotos: data })),
  setActivePhoto: (activePhotoIndex) =>
    set((state) => ({
      activePhoto: { index: activePhotoIndex, ...state.allPhotos.find((photo, index) => index === activePhotoIndex) },
    })),
  showPhotoCarousel: () => set(() => ({ isPhotoCarouselVisible: true })),
  hidePhotoCarousel: () => set(() => ({ isPhotoCarouselVisible: false })),
  setPhotoLikeStatus: (photoId, likeStatus) =>
    set((state) => ({
      allPhotos: state.allPhotos.map((photo) => {
        if (photo.id === photoId) {
          photo.isLiked = likeStatus
          return photo
        } else {
          return photo
        }
      }),
    })),
}))

export { usePhotoCarouselStore }
