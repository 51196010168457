import { useFavoritePhotosStore } from "features/masonry/store"

const selector = (state) => state.favoritePhotos

function useGetFavoritePhotos() {
  const favoritePhotos = useFavoritePhotosStore(selector)

  return favoritePhotos
}

export { useGetFavoritePhotos }
